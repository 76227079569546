import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styles from "./Layout.module.scss";
import Test from "../page/test/test";
import useToken from './useToken';
import Login from '../page/login/Login';

  function Layout() {
    const { token, setToken } = useToken();

    if(!token) {
        return <Login setToken={setToken} />;
    }

    return (
      
         <div className={styles.wrapper}>
      <h1>Application</h1>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Test />} />
        </Routes>
      </BrowserRouter>
    </div>
    )
  }

//   function NavItem() {
//     return (
//         <ul className={styles.nav}>
//             {navItem.map((item) => {
//               if (item.nav)
//                 return (
//                    <li className={styles.navItem}><Link to={item.path}>{item.name}</Link></li>
//                )})}
//         </ul>         
//     );
//   }
  
  export {Layout}
