import React from 'react'
import styles from './App.module.scss'
import {Layout} from "./layout/Layout"
import {isMobile} from 'react-device-detect';

function App() {
  if (!isMobile) {
    return (
      <div className={styles.mobile}>
        <h1>Cette application est accessible uniquement sur smartphone.</h1>
      </div>
    )
  }
  return (
    <>
      <div className={styles.main}>
        <Layout />
      </div>
    </>
  )
}

export default App
