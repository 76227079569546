
import styles from './Login.module.scss';
import no_minor from "../../assets/no-minor.png";
import Logo from "../../assets/logoClub.webp";
import { useForm } from "react-hook-form";
import axios from 'axios';

const copyright = "L'abûzif poker club © 2024"

export default function Login({ setToken }: { setToken: any }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: any) => formAction(data)
  setToken(onSubmit);
  
  return(
    <>
    <header className={styles.header}>
      <img src={Logo} alt="logo club" className={styles.logo}/>
      <h1 className={styles.title}>L'abûzif poker club</h1>
    </header>
    <main className={styles.main}>
        <div className={styles.container}>
          <h2 className={styles.title}>Accès membres</h2>
          <form className={styles.formContact} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formItem}>
              <label htmlFor="pseudo">Pseudo <span className={styles.red}>*</span></label>
              <p><input type="text" placeholder="Pseudo" id="pseudo" {...register("pseudo", {required: true})}/></p>
              {errors.lastName && <span className={styles.red}>Veuillez saisir votre pseudo !</span>}
            </div>
            <div className={styles.formItem}>
              <label htmlFor="Password">Mot de passe <span className={styles.red}>*</span></label>
              <p><input type="password" placeholder="Mot de passe" id="password" {...register("password", {required: true})}/></p>
              {errors.lastName && <span className={styles.red}>Veuillez saisir un mot de passe valide !</span>}
            </div>
            <div className={styles.formItem}>
              <p className={styles.blockSubmit}><input type="submit" name="sendMessage" value="Envoyer" className={styles.button62}/></p>
            </div>
          </form>
        </div>
      </main>
    <footer className={styles.footer}>
      <div className={styles.container}>
        <ul className={styles.socialNetwork}>
          <li className={styles.socialItem}><img src={no_minor} alt="logo no minor" className={styles.noMinor}/></li>
        </ul>
        <p className={styles.copyright}>{copyright}</p>
      </div>
    </footer>
      </>
  )
}

async function formAction(data: {pseudo: string; password: string;}) {
  axios.post('https://members.labuzifpokerclub.fr/back/api/users/login.php', {
    login: data.pseudo,
    password: data.password,
  })
  .then(function (response) {
    return JSON.stringify(response);
  })
  .catch(function (error) {
    console.log(error);
  });
}
